import { toast } from "react-toastify";
import { getProfileData } from "../Profile/ProfileActions";
import { AuthPageAction } from "./AuthReducer";
import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const loginUser = (user) => async (dispatch) => {
  if (localStorage.getItem("token")) {
    localStorage.removeItem("token");
  }
  try {
    dispatch(AuthPageAction.getLoginRequest());
    const { email, password } = user;
    const response = await axios.post(`${API_BASE_URL}/users/login`, {
      email,
      password,
    });
    const token = response.data.token;
    localStorage.setItem("token", token);
    dispatch(AuthPageAction.getLoginSuccess(response.data));
    dispatch(getProfileData());
  } catch (e) {
    dispatch(AuthPageAction.getLoginFailure(e.response.data.message));
  }
};

export const signInUser = (user) => async (dispatch) => {
  try {
    dispatch(AuthPageAction.getSignUpRequest());
    const { username, email, password } = user;
    const res = await axios.post(`${API_BASE_URL}/users/signIn`, {
      username,
      email,
      password,
      role: "user",
    });

    dispatch(AuthPageAction.getSignUpSuccess(res.data));
  } catch (error) {
    const errorMessage = error?.response?.data?.message || error?.message;

    dispatch(AuthPageAction.getSignUpFailure(errorMessage));
  }
};
