import { getUserSubscription } from "../Profile/ProfileActions";
import { SubscriptionsPageAction } from "./SubscriptionsReducers";
import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getSubscriptions = () => async (dispatch) => {
  try {
    dispatch(SubscriptionsPageAction.getSubscriptionsDataRequest());
    const response = await axios.get(`${API_BASE_URL}/packages`);
    dispatch(
      SubscriptionsPageAction.getSubscriptionsDataSuccess(response.data)
    );
  } catch (e) {
    dispatch(SubscriptionsPageAction.getSubscriptionsDataFailure(e.message));
  }
};

export const addSubscription = (subscriptionDetail) => async (dispatch) => {
  try {
    console.log("addSubscription, subscriptionDetail:", subscriptionDetail);
    dispatch(SubscriptionsPageAction.getSubscriptionsDataRequest());
    const response = await axios.post(`${API_BASE_URL}/subscriptions/create`, {
      userId: subscriptionDetail.user.id,
      packageId: subscriptionDetail?.subscriptionDetails?.subscription?.id,
      productIds: subscriptionDetail.subscriptionDetails.perfumes.map(
        (product) => product.id
      ),
    });
    dispatch(
      SubscriptionsPageAction.getSubscriptionsDataSuccess(response.data)
    );
  } catch (e) {
    console.log(e.message);
    dispatch(SubscriptionsPageAction.getSubscriptionsDataFailure(e.message));
  }
};

export const removeSubscription = (subscription) => async (dispatch) => {
  console.log("Removing subscription, subscription:", subscription);

  try {
    dispatch(SubscriptionsPageAction.getSubscriptionsDataRequest());
    const response = await axios.delete(
      `${API_BASE_URL}/subscriptions/delete/${subscription.id}`
    );
    dispatch(getUserSubscription());
    dispatch(
      SubscriptionsPageAction.getSubscriptionsDataSuccess(response.data)
    );
  } catch (e) {
    console.log(e.message);
    dispatch(SubscriptionsPageAction.getSubscriptionsDataFailure(e.message));
  }
};
