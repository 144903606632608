import axios from "axios";
import { CheckoutActions } from "./CheckoutReducers";

export const addOrder = (orderDetails) => async (dispatch) => {
  console.log(orderDetails);
  try {
    dispatch(CheckoutActions.addOrderRequest());
    const response = await axios.post(
      process.env.REACT_APP_API_BASE_URL + "/orders/create",
      orderDetails
    );

    dispatch(CheckoutActions.addOrderSuccess(response.data));
  } catch (e) {
    dispatch(CheckoutActions.addOrderFailure(e.message));
  }
};
