import React from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { removeSubscription } from "../../../Redux/Subscriptions/SubscriptionsActions";
const RemoveSubscriptionModal = ({ isOpen, onClose, subscription }) => {
  const dispatch = useDispatch();
  const customStyles = {
    content: {
      width: "75%",
      height: "75%",
      margin: "auto",
      padding: "20px",
      backgroundColor: "#313131",
      color: "white",
      borderRadius: "10px",
    },
    overlay: {
      zIndex: 1100,
      backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
  };

  const handleConfirm = () => {
    dispatch(removeSubscription(subscription));
    console.log("Removing subscription");
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={customStyles}>
      <div className="w-full mx-auto text-white p-6 bg-gray-800 rounded-lg shadow-lg">
        <h1 className="text-4xl font-bold pb-6">Confirm Your Choice</h1>
        <p className="text-lg pb-8">
          Are you sure you want to select the perfume:{" "}
          <span className="font-semibold">{subscription.package?.name}</span>?
        </p>
        <div className="flex flex-col md:flex-row justify-end gap-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
          >
            Cancel
          </button>
          <button
            onClick={handleConfirm}
            className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700"
          >
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default RemoveSubscriptionModal;
