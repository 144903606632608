import React, { useEffect, useState } from "react";
import "./style.css";
import Footer1 from "../../Components/Footer1/footer1";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { addOrder } from "../../Redux/Checkout/CheckoutActions";
import { getProfileData } from "../../Redux/Profile/ProfileActions";
import { emptyCart } from "../../Redux/Cart/CartActions";
import { useNavigate } from "react-router-dom";
import { isTokenValid } from "../../utils/verifyToken";
import { countries } from "../../Shared/countries";

const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cart } = useSelector((store) => store.cartReducer);
  const { profileData } = useSelector((store) => store.profileReducer);
  const [isOpenProceedModal, setIsOpenProceedModal] = useState(false);
  const openModal = () => {
    setIsOpenProceedModal(true);
  };
  const closeModal = () => {
    setIsOpenProceedModal(false);
  };

  useEffect(() => {
    if (!isTokenValid()) {
      navigate("/auth");
    }
  }, [navigate]);

  useEffect(() => {
    dispatch(getProfileData());
  }, [dispatch]);

  const [total, setTotal] = useState();

  useEffect(() => {
    if (cart) {
      let total = 0;
      cart.forEach((element) => {
        total += element.perfume.price * element.quantity;
      });
      setTotal(total);
    }
  }, [cart]);

  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const [apartment, setApartment] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const [creditCardNumber, setCreditCardNumber] = useState("");
  const [billingPostalCode, setBillingPostalCode] = useState("");

  const formatAddress = () => {
    return `${address}, ${
      apartment ? apartment + ", " : ""
    }${city}, ${postalCode}, ${country}`;
  };

  const handleConfirmCheckout = () => {
    dispatch(
      addOrder({
        userId: profileData.id,
        location: formatAddress(),
        date: new Date().toISOString(),
        quantity: cart.reduce((acc, product) => acc + product.quantity, 0),
        status: "Pending",
        amount: total,
        product_ids: cart.map((order) => ({
          productId: order.perfume.id,
          quantity: order.quantity,
        })),
      })
    );
    dispatch(emptyCart());
    navigate("/");
    closeModal();
  };

  const checkInputs = () => {
    if (
      country === "" ||
      address === "" ||
      apartment === "" ||
      city === "" ||
      postalCode === "" ||
      creditCardNumber === "" ||
      billingPostalCode === ""
    ) {
      toast.error("Please fill all the fields");
      return;
    }

    openModal();
  };
  return (
    <>
      <Modal
        isOpen={isOpenProceedModal}
        onRequestClose={closeModal}
        className="w-1/2 bg-[#313131] p-5 mx-auto rounded shadow-lg"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      >
        <div>
          <h1 className="text-2xl font-semibold mb-4 text-white">
            Proceed to Checkout
          </h1>
          <p className="mb-6 text-white">
            Are you sure you want to proceed to checkout?
          </p>
          <div className="flex justify-end gap-2">
            <button
              onClick={closeModal}
              className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 transition cursor-pointer"
            >
              Cancel
            </button>
            <button
              onClick={handleConfirmCheckout}
              className="px-4 py-2 bg-[#D9D9D9] text-black rounded hover:bg-gray-400 transition cursor-pointer"
            >
              Confirm
            </button>
          </div>
        </div>
      </Modal>

      <div className="w-full bg-[#313131] text-white py-20 helvetica">
        {/* Title */}
        <div className="w-4/5 mx-auto">
          <h1 className="text-5xl richmond_display">Checkout</h1>
        </div>

        <div className="w-4/5 mx-auto flex flex-col lg:flex-row gap-2 gap-y-10">
          {/* Delivery Address and Payment */}
          <div className="flex-1 flex flex-col gap-2 mt-10 helvetica">
            <div className="flex-1">
              <h1 className="text-3xl">Delivery Address</h1>

              <form
                id="deliveryAddress"
                action=""
                className="flex flex-col gap-2 w-full mt-2"
              >
                <select
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  className="bg-transparent"
                >
                  <option value="" className="text-white">
                    Select Country
                  </option>
                  {countries.map((country) => (
                    <option key={country} value={country}>
                      {country}
                    </option>
                  ))}
                </select>

                <input
                  type="text"
                  placeholder="Address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Apartment, suite, etc. (optional)"
                  value={apartment}
                  onChange={(e) => setApartment(e.target.value)}
                />

                <div className="flex flex-col xl:flex-row gap-2">
                  <input
                    type="text"
                    placeholder="City"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    className="flex-1"
                  />
                  <input
                    type="text"
                    placeholder="Postal Code"
                    value={postalCode}
                    onChange={(e) => setPostalCode(e.target.value)}
                    className="flex-1"
                  />
                </div>
              </form>
            </div>

            <div className="flex-1 flex flex-col gap-2 ">
              <h1 className="text-3xl">Payment</h1>
              <h1 className="bg-[#D9D9D9] p-3">Credit Card</h1>

              <form
                id="payment"
                action=""
                className="flex flex-col gap-2 w-full "
              >
                <input
                  type="text"
                  placeholder="Credit card Number"
                  value={creditCardNumber}
                  onChange={(e) => setCreditCardNumber(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Postal Code"
                  value={billingPostalCode}
                  onChange={(e) => setBillingPostalCode(e.target.value)}
                  className="flex-1"
                />
              </form>
            </div>
          </div>

          <div className="flex-1 border_2 p-2">
            {cart.map((element, index) => (
              <div key={index}>
                <div className="flex flex-row justify-between">
                  <div className="flex-1 flex flex-row items-center gap-2">
                    <img
                      src={
                        process.env.REACT_APP_BASE_URL + element.perfume.image
                      }
                      width={60}
                      alt=""
                    />
                    <div className="flex flex-col gap">
                      <span>{element.perfume.name}</span>
                      <span className="opacity-70">
                        QTY : {element.quantity}
                      </span>
                    </div>
                  </div>

                  <div className="flex flex-1 items-center justify-end">
                    <span className="opacity-70">
                      $ {element.perfume.price}
                    </span>
                  </div>
                </div>
                <hr />
              </div>
            ))}

            <div className="flex flex-row justify-between pt-5">
              <div className="flex-1 flex flex-row items-center gap-2">
                <span className="text-2xl">Total</span>
              </div>

              <div className="flex flex-1 items-center justify-end">
                <span>$ {total}</span>
              </div>
            </div>

            <div
              className="p-1 px-5 border cursor-pointer mt-12 w-1/2 text-center bg-white text-black float-right"
              onClick={checkInputs}
            >
              <span>Checkout</span>
            </div>
          </div>
        </div>
      </div>

      <Footer1 />
    </>
  );
};

export default Checkout;
