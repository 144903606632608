import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { getProfileData } from "../../Redux/Profile/ProfileActions";
import { addSubscription } from "../../Redux/Subscriptions/SubscriptionsActions";
import { toast } from "react-toastify";

const stripePromise = loadStripe("your-publishable-key-here");

const CheckoutForm = ({
  subscriptionDetails,
  closeModal,
  closeSubscriptionModal,
}) => {
  const dispatch = useDispatch();
  const { profileData } = useSelector((store) => store.profileReducer);

  useEffect(() => {
    dispatch(getProfileData());
  }, [dispatch]);

  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(
      addSubscription({
        user: profileData,
        subscriptionDetails: subscriptionDetails,
      })
    );
    toast.success("You Have Successfully Subscribed!");
    closeModal();
    closeSubscriptionModal();
    // setIsProcessing(true);

    // const cardElement = elements.getElement(CardElement);

    // const { error, paymentMethod } = await stripe.createPaymentMethod({
    //   type: "card",
    //   card: cardElement,
    // });

    // if (error) {
    //   setError(error.message);
    //   setIsProcessing(false);
    // } else {
    //   setError(null);
    // Call your backend to create the PaymentIntent
    // const response = await fetch("/create-payment-intent", {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify({ paymentMethodId: paymentMethod.id }),
    // });

    //const paymentIntent = await response.json();

    // if (paymentIntent.error) {
    //   setError(paymentIntent.error);
    //   setIsProcessing(false);
    // } else {
    //   setSuccess(true);
    //   setIsProcessing(false);
    // }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement options={{ style: { base: { fontSize: "16px" } } }} />
      {error && <div style={{ color: "red" }}>{error}</div>}
      {success && <div style={{ color: "green" }}>Payment successful!</div>}
      <button
        type="submit"
        disabled={!stripe || isProcessing}
        className="bg-blue-500 text-white py-2 px-4 rounded mt-4"
      >
        {isProcessing ? "Processing..." : "Pay Now"}
      </button>
    </form>
  );
};

const StripeModal = ({
  isOpenModal,
  closeModal,
  subscriptionDetails,
  closeSubscriptionModal,
}) => {
  const customStyles = {
    content: {
      width: "50%",
      height: "75%",
      margin: "auto",
      padding: "20px",
      backgroundColor: "#313131",
      color: "white",
      borderRadius: "10px",
    },
    overlay: {
      zIndex: 1300,
      backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
  };
  return (
    <Modal
      isOpen={isOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
    >
      <Elements stripe={stripePromise}>
        <CheckoutForm
          subscriptionDetails={subscriptionDetails}
          closeModal={closeModal}
          closeSubscriptionModal={closeSubscriptionModal}
        />
      </Elements>
    </Modal>
  );
};

export default StripeModal;
